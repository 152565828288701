.app_privacy.flex {
  @include grid-column-span(1, 16);
  @include grid-row-span(1, 16);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  background-color: #fff;

  .wrap {
    width: 1200px;
  }

  .app_titlebar {
    width: 1200px;
    padding: 32px 0;
    margin: 40px 0 8px;
    background-color: white;

    .ui.header {
      font-size: 22px;
    }
  }

  section.flex {
    display: flex;
    justify-content: center;
    width: 1200px;
    padding: 23px 90px 32px;
    margin-bottom: 8px;
    background-color: white;

    .contents {
      width: 80%;
    }

    li {
      list-style: none;
    }
  }

  .turn_landing.flex {
    display: flex;
    justify-content: center;
    width: 1200px;
    padding: 32px 0;
    margin-bottom: 8px;

    a {
      color: black;

      &:visited {
        color: black;
      }
    }

    a {
      &:hover {
        text-decoration: underline;
        opacity: 0.8;

        @include mq_down("sm") {
          text-decoration: none;
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    background-color: white;

    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .app_titlebar.flex {
        justify-content: center;
        width: 340px;
        margin: 1px 0;
        background-color: white;

        .ui.header {
          font-size: 16px;
        }
      }

      section.flex {
        justify-content: center;
        padding: 0;
        background-color: white;
      }

      .turn_landing.flex {
        display: flex;
        justify-content: center;
        width: 320px;
        padding-bottom: 32px;
        background-color: white;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    background-color: white;

    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .app_titlebar.flex {
        justify-content: center;
        width: 100%;
        margin: 0 0 8px;
        background-color: white;

        .ui.header {
          font-size: 16px;
        }
      }

      section.flex {
        justify-content: center;
        padding: 34px 0;
        background-color: white;
      }

      .turn_landing.flex {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-bottom: 32px;
        background-color: white;
      }
    }
  }
}
