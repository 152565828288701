.app_signup.flex {
  display: flex;
  flex-direction: column;
  align-items: center;

  .caution-message {
    margin: 0 16px 16px;
    font-size: 12px;
  }

  .rectangle {
    width: 320px;
    height: 69px;
    padding: 12px 8px;
    margin-bottom: 16px;

    p {
      width: 100%;
      height: auto;
      font-family: HiraKakuProN-W3;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1.71;
      color: #06318f;
      letter-spacing: normal;
    }
    background-color: #f2f6ff;
    border: solid 1px #06318f;
    border-radius: 4px;

    @include mq_down('sm') {
      width: 100%;
      height: auto;
    }
  }

  .wrapper_content {
    max-width: 456px;
    width: 100%;
  }

  .app_titlebar {
    height: auto;
    padding: 56px 0;
    background-color: white;

    .ui.header {
      font-size: 26px;
      color: $app-color-black;
    }
  }

  .domain_guide {
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: 12px;
  }

  .or {
    display: flex;
    justify-content: center;
    width: 456px;
    padding: 24px 0 8px;
    font-size: 12px;
  }

  .app_disabled {
    pointer-events: none;
    opacity: 0.45;
  }

  .w__sns_signup_content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;

    .ui.image {
      margin-left: 14px;
    }

    .signup_fb {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .content.fb {
        margin-left: 8px;
      }
    }

    .signup_g {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .content.g {
        margin-left: 8px;
      }
    }

    .ui.image {
      &.fb {
        width: #{$icon-width}px;
        height: #{$icon-height}px;
        border-radius: 4px;
      }

      &.g {
        width: #{$icon-width}px;
        height: #{$icon-height}px;
      }
    }
  }

  .liner.flex.bottom {
    padding: 40px 0 0;
  }

  .content {
    &.fb {
      text-align: center;
    }

    &.g {
      text-align: center;
    }
  }

  .w__signup_submit_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;

    .ui.image {
      margin-left: 14px;
    }

    .signup_mail {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .ui.image.email {
        display: inline-block;
        width: #{$icon-width}px;
        height: #{$icon-height-email}px;
      }

      .content.email {
        margin-left: 8px;
      }
    }
  }

  form.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    background-color: white;

    .sns_buttons.flex {
      display: flex;
      flex-wrap: wrap;
      width: 320px;

      .ui.button {
        width: 100%;
        height: 48px;
        color: $app-color-black;
      }

      .btn_fb {
        margin-bottom: 16px;
        background-color: $app-white-button !important;
        border: solid 1px $app-black-border;

        @include hover_button_opacity();

        &:hover {
          @include mq_down('md') {
            opacity: 1;
          }
        }
      }

      .btn_google {
        background-color: $app-white-button !important;
        border: solid 1px $app-black-border;

        @include hover_button_opacity();

        &:hover {
          @include mq_down('md') {
            opacity: 1;
          }
        }
      }

      .ui.button {
        padding: 0;
      }
    }

    input {
      -webkit-appearance: none;
    }
  }

  .liner.flex {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: $app-gray-border;

    .div_liner.flex {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 320px;
    }
  }

  .amplify-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .login_error_message {
      display: none;
      width: 320px;
      margin-bottom: 10px;
      font-size: 12px;
      color: #d62d2d;

      &.display_error {
        display: block !important;
      }
    }

    .ui.input {
      width: 320px;
      height: 48px;
      margin-top: 16px;
      margin-bottom: 0;
    }

    .prefecture {
      display: flex;
      align-items: center;
      width: 320px;
      height: 48px;
      margin-top: 16px;
      margin-bottom: 0;

      .search {
        top: auto;
      }

      i {
        margin: -0.4em !important;
      }
    }

    .validateError {
      width: 320px;
      text-align: left;

      .syncValidateError {
        font-size: #{$app-font-size-error}px;
        color: $error-red;
      }
    }

    .link_forgotpassword {
      width: 320px;
      margin-bottom: 24px;
      text-align: left;
    }

    .ui.button.submit_login {
      width: 320px;
      height: 48px;
      padding: 0;
      color: white;
      background-color: #ffc107;

      &:hover {
        background-color: lighten(#ffc107, 10%);

        @include mq_down('md') {
          background-color: #ffc107;
        }
        opacity: 0.8;
        transition-duration: 0.4s;
      }
    }

    .input-field {
      width: 256px;
    }

    .field-group.agreement {
      display: flex;
      align-items: flex-start;
      width: 320px;
      margin: 20px 0;
      line-height: $app-line-height-main;

      &:focus {
        outline: none;
      }

      a {
        &:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down('md') {
            color: $hover-blue-sm;
            opacity: 1;
          }
        }
      }

      .ui.button {
        border: none;
      }

      .is_agreement {
        position: relative;
        top: 3px;
        margin-right: 7px;
      }
    }

    .field-group.recaptcha {
      justify-content: center;
      width: 304px;
      margin-top: 20px;
    }

    .w__beta-signin {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .signin-beta {
        width: 320px;
        padding-top: 24px;

        @include mq_down('sm') {
          width: 100%;
        }
      }

      a.signinLink-beta {
        font-weight: bold;

        &:hover {
          text-decoration: underline;
          opacity: 0.7;
        }
      }
    }
  }

  footer.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 320px;
    padding: 24px 0;
    margin: 0 auto;
    background-color: white;

    .attention_signin {
      width: 100%;
      padding-bottom: 16px;
      color: $app-color-black;

      .signinLink {
        font-weight: bold;

        &:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down('md') {
            color: $hover-blue-sm;
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }

    .ui.button.basic.btn_signup {
      width: 320px;
      height: 48px;
      margin-bottom: 44px;
      font-weight: 600 !important;
      color: #3f98e2 !important;
      box-shadow: 0 0 0 1px #3f98e2 inset;
    }

    .gotop {
      width: 100%;
      color: black;

      &:visited,
      &:active {
        color: black;
      }
    }
  }

  .return_top {
    margin-top: 44px;
    text-align: center;
  }

  .ui.button.disabled_button {
    padding: 0;
    font-weight: normal;
    color: #000;
    text-align: center;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  }

  .ui.button.agreement_disabled_button {
    padding: 0;
    font-weight: normal;
    color: #000;
    text-align: center;
    cursor: pointer;
    background-color: transparent;

    a:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 767px) {
    /**
     * Under iPad
     */
    background-image: none !important;

    .wrapper {
      width: 100%;
    }

    .w__sns_signup_content {
      justify-content: center;

      .ui.image {
        margin-left: 0;
      }

      .content {
        width: auto;
        text-align: center;
      }
    }

    .w__signup_submit_btn {
      justify-content: center;

      .ui.image {
        margin-left: 0;
      }

      .content {
        width: auto;
        text-align: center;
      }
    }

    .app_titlebar {
      width: 100%;
      height: auto;
      padding: 32px 0;

      .ui.header {
        font-size: 16px;
      }
    }

    form.flex {
      flex-flow: column nowrap;
      align-items: center;
      padding: 0 16px;

      .sns_buttons.flex {
        width: 100%;
      }
    }

    .liner.flex.bottom {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      .div_liner.flex {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
      }

      span {
        display: flex;
        align-items: center;
        width: 50px;
        height: 12px;
        padding: 0 7px;
        font-size: 12px;
      }
    }

    .amplify-container {
      display: block;
      width: 100%;

      .field-group {
        width: 100%;

        .ui.input {
          width: 100%;
        }

        &.agreement {
          width: 100%;
        }

        &.recaptcha {
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }

      .prefecture {
        width: 100%;
      }

      .ui.button.submit_login {
        width: 100%;
      }
    }

    footer.flex {
      width: 100%;
      padding: 24px 16px;
      margin: 0 auto;

      a {
        width: 256px;

        .ui.button.basic.btn_signup {
          width: 256px;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .recaptcha {
      transform: translateX(-6.75px);
    }
  }

  .b__footer {
    ul {
      display: flex;
      justify-content: center;
      width: 100%;
      list-style: none;
      padding: {
        left: 0;
      }
      margin: {
        bottom: 24px;
      }

      @include mq_down('sm') {
        flex-direction: column;
        align-items: center;
      }

      li {
        padding: {
          right: 1rem;
        }

        &:last-of-type {
          padding: {
            right: 0;
          }
        }

        @include mq_down('sm') {
          padding: {
            right: 0;
            bottom: 8px;
            left: 0;
          }
        }

        a {
          color: #4183c4;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            opacity: 0.7;
          }
        }
      }
    }
  }
}
