.app_law {
  grid-row: 1 / span 16;
  grid-column: 1 / span 16;
  background-color: #fff;
  $background-color: #fff;
  $text-color: #4a4a4a;

  > .grid_law {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 20px;

    .breadcrumbs_list {
      display: flex;
      grid-area: b;
      margin-top: 8px;
      font-size: 14px;
    }

    .breadcrumbs_title {
      padding-right: 10px;
    }

    .breadcrumbs_second_title {
      padding-left: 10px;
      font-weight: 600;
      text-decoration: underline;
    }

    .title_container {
      width: 1200px;
      padding: 56px 0;
      text-align: center;
      background-color: $background-color;

      h2 {
        color: $app-color-black;
      }

      @include mq_down("sm") {
        padding: 32px 0 10px;
      }
    }
    .title {
      align-self: center;
      justify-self: center;
      font-size: 26px;
      @include mq_down("sm") {
        font-size: 22px;
      }
    }

    .icon_law_left {
      padding-bottom: 5px;
      margin-right: 5px;
    }

    .icon_law_right {
      padding-bottom: 10px;
      margin-left: 5px;
    }

    .ui.header.title {
      margin: 0;
      line-height: $app-line-height-main;
    }

    .contents_container {
      width: 80%;
      padding: 22px 0 0;
      margin: auto;
      font-size: 14px !important;
      @include mq_down("sm") {
        width: 100%;
        padding: 22px 16px 0 16px;
      }
    }

    .contents {
      width: 1200px;
      padding: 0;
      background-color: $background-color;

      .contents-text {
        color: $app-color-black;
      }
    }

    .contents-title {
      padding: 0;
      font-size: 18px;
      font-weight: bold;
      color: $app-color-black;
      word-wrap: break-word;
      @include mq_down("sm") {
        font-size: 16px;
      }
    }
    .contents-text.bottom {
      padding-bottom: #{$body-bottom}px;
    }
    .contents-text {
      padding: 0 0 14px;
      font-size: 14px;
      color: $text-color;
      word-wrap: break-word;

      .text-space {
        margin-left: 8rem;
      }

      a {
        &:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down("sm") {
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }

    .return-area {
      display: flex;
      justify-content: center;
      order: 3;
      width: 100%;
      padding: 32px 0;

      a {
        color: $app-color-black;
      }

      a:hover {
        text-decoration: underline;
        opacity: 0.7;

        @include mq_down("sm") {
          text-decoration: none;
          opacity: 1;
        }
      }
    }

    .return_title {
      align-self: center;
      justify-self: center;
      font-size: 14px;
    }

    .law_end {
      color: $app-color-black;
      text-align: center;
    }
  }
}

@include mq_down("lg") {
  // iPad iPhone 8  iPhone 7 Plus iPhone 6 iPhone 5, iPhone SE
  .app_law {
    background-color: white;

    > .grid_law {
      grid-template-columns: repeat(16, 1fr);
    }

    .breadcrumbs_list {
      display: none !important;
    }

    .title_container {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .contents {
      padding-bottom: 0 !important;
    }

    .contents_container {
      width: 80%;
      padding: 22px 0 0 !important;
      margin: auto;
      font-size: 14px !important;

      @include mq_down("sm") {
        padding: 22px 16px 0 !important;
      }
    }

    .icon_law_right {
      padding-bottom: 6px !important;
    }

    .contents-title {
      padding: 0 !important;
      font-size: 18px;
    }

    .contents-text {
      font-size: 14px !important;
    }

    .return_title {
      align-self: center;
      justify-self: center;
      font-size: 14px !important;
    }

    .return-area {
      background-color: #fff !important;
    }
  }
}
