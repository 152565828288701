.container {
  padding: 64px 20px;

  &__inner {
    max-width: 980px;
    margin: auto;
  }

  h1 {
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 22px;
    }
  }
}

.faq {
  $this: &;
  margin-top: 40px;

  &__item {
    & + #{$this}__item {
      margin-top: 40px;
    }
  }

  &__heading {
    background: #f1f3f5;
    padding: 20px;
    border-radius: 16px;
    font-size: 16px;

    &::before {
      content: 'Q.';
      margin-right: 0.5em;
    }
  }

  &__answer {
    padding: 20px;

    @media screen and (max-width: 768px) {
      padding: 0 20px;
    }

    b {
      text-decoration: underline; /* 下線 */
      text-decoration-thickness: 0.5em; /* 線の太さ */
      text-decoration-color: rgba(255, 228, 0, 0.4); /* 線の色 */
      text-underline-offset: -0.2em; /* 線の位置。テキストに重なるようにやや上部にする */
      text-decoration-skip-ink: none; /* 下線と文字列が重なる部分でも下線が省略されない（線が途切れない） */
    }

    .title {
      padding: 1.25rem 0;
      font-weight: bold;
      font-size: 16px;
      position: relative;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        content: '';
        background-image: -webkit-repeating-linear-gradient(
          135deg,
          #999,
          #999 1px,
          transparent 2px,
          transparent 5px
        );
        background-image: repeating-linear-gradient(
          -45deg,
          #999,
          #999 1px,
          transparent 2px,
          transparent 5px
        );
        background-size: 7px 7px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }

      &:not(:first-child) {
        margin-top: 2em;
      }
    }
  }
}
