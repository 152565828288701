.b__featureList_image_before,
.b__featureList_image_after {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 #{$b__featureList_side}px;
  margin-top: 16px;
  background-image: url('https://fruitsale.jp/assets/hero_lg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: #{$feature-wrap-max-width}px;
    margin: #{$feature-wrap-vertical-lg}px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: $app-color-black;
    letter-spacing: normal;

    a {
      width: 100%;
    }

    .ui.button {
      width: 100%;
      height: #{$explain-button-height}px;
      padding: #{$explain-button-pVertical}px #{$explain-button-pSide}px;
      margin-top: #{$button_mTop}px;
      color: $app-color-white;

      @include mq_down() {
        height: #{$explain-button-height-md}px;
        padding: #{$explain-button-p-md}px;
      }
    }

    .heading {
      width: 100%;
      height: #{$feature-list-heading-size}px;
      margin: 0 auto #{$feature-list-heading-bottom-lg}px;
      font-family: HiraKakuProN-W6;
      font-size: #{$feature-list-heading-size}px;
      font-weight: bold;
      text-align: center;
    }

    .list_item {
      width: 100%;
      padding-left: 1em;
      margin-bottom: #{$feature-list-item-bottom}px;
      font-family: HiraKakuProN-W3;
      font-size: #{$app-font-size-main}px;
      line-height: #{$feature-list-line-height}px;
      text-indent: -1em;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        &:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down('md') {
            color: $hover-blue-sm;
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }
  }

  @include mq_down('md') {
    background-image: url('https://fruitsale.jp/assets/hero-image-md.svg');

    .wrap {
      margin: #{$feature-wrap-vertical-md}px 0;

      .heading {
        margin-bottom: #{$feature-list-heading-bottom-md}px;
      }
    }
  }

  @include mq_down('sm') {
    background-image: url('https://fruitsale.jp/assets/hero_sm_fix.svg');

    .wrap {
      margin: #{$feature-wrap-vertical-sm}px 0;

      .heading {
        margin-bottom: #{$feature-list-heading-bottom-sm}px;
        font-size: #{$feature-list-heading-size-sm}px;
      }
    }
  }

  @include mq_down('xs') {
    .wrap {
      margin: #{$feature-wrap-vertical-xs}px 0;
    }
  }
}

.b__featureList_image_before {
  margin-top: 0;
}
