.a__company {
  .b__title-header {
    padding: 56px 0;
    margin: 0;
    font-size: 26px;
    text-align: center;
    @include mq_down("sm") {
      font-size: 22px;
      line-height: 1.5;
      padding: {
        top: 32px;
        bottom: 10px;
      }
    }
  }
  .b__company-info {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: {
      right: auto;
      left: auto;
    }
    padding: {
      top: 22px;
      bottom: 78px;
    }
    .w__section {
      width: 80%;
      margin: auto;
      .header-label {
        font-size: 18px;
        font-weight: bold;
        @include mq_down("sm") {
          font-size: 16px;
        }
      }
      padding: {
        bottom: 36px;
      }
      .content {
        padding: {
          top: 4px;
        }
        @include mq_down("sm") {
          padding: 0;
        }
      }
      @include mq_down("sm") {
        padding: {
          top: 0;
          right: 16px;
          left: 16px;
        }
        margin: 0;
      }
    }
    @include mq_down("md") {
      width: auto;
    }
  }
}
