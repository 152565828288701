.app_terms.flex {
  @include grid-column-span(1, 16);
  @include grid-row-span(1, 16);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  background-color: #fff;

  .wrap {
    width: 1200px;
  }

  .app_titlebar {
    width: 1200px;
    padding: 56px 0;
    background-color: white;
    margin: {
      bottom: 0 !important;
    }

    @media screen and (max-width: 767px) {
      padding: 32px 0;
    }

    .ui.header {
      margin: 0;
      font-size: 26px;
      line-height: $app-line-height-main;
      color: $app-color-black;
    }
  }

  section.flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1200px;
    padding: 0 0 #{$body-bottom}px;
    padding-top: 0 !important;
    background-color: white;

    .update-at {
      width: 100%;
      text-align: right;
    }

    .contents {
      width: 80%;

      .ui.medium.header.item_title {
        padding-top: 22px;
      }

      @include mq_down("sm") {
        width: 100%;
        padding: 0 16px;
      }
    }

    li {
      list-style: none;
    }
  }

  .turn_landing.flex {
    display: flex;
    justify-content: center;
    width: 1200px;
    padding: 32px 0;
    margin-bottom: 8px;

    a {
      color: $app-color-black;

      &:visited {
        color: $app-color-black;
      }
    }

    a {
      &:hover {
        text-decoration: underline;
        opacity: 0.8;

        @include mq_down("sm") {
          text-decoration: none;
          opacity: 1;
        }
      }
    }
  }

  @include mq_down("sm") {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    background-color: white;

    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .app_titlebar.flex {
        justify-content: center;
        width: 340px;
        padding: 32px 0;
        margin: 1px 0;
        background-color: white;

        .ui.header {
          font-size: 16px;
        }
      }

      section.flex {
        justify-content: center;
        padding: 0;
        background-color: white;

        .item_title {
          font-size: 16px;
        }
      }

      .turn_landing.flex {
        display: flex;
        justify-content: center;
        width: 320px;
        padding-bottom: 32px;
        background-color: white;
      }
    }
  }

  @include mq_down("lg") {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    background-color: white;

    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .app_titlebar.flex {
        justify-content: center;
        width: 100%;
        margin: 0 0 8px;
        background-color: white;

        .ui.header {
          font-size: 26px;
        }
      }

      section.flex {
        justify-content: center;
        padding: 34px 0 #{$body-bottom}px;
        background-color: white;
      }

      .turn_landing.flex {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-bottom: 32px;
        background-color: white;
      }
    }
  }
}
